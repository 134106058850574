import { makeAutoObservable } from "mobx"
import { ApiDataModel } from '../Components/Models';


class ApiData {
  version: string = "";

  constructor() {
    makeAutoObservable(this)
  }

  setData = (data: ApiDataModel) => {
    this.version = data.version;
  }
}

export default ApiData;
