import '@mantine/core/styles.css';
import '@mantine/notifications/styles.css';
import 'mantine-datatable/styles.layer.css';

import { useEffect, useState } from 'react';
import { Amplify } from 'aws-amplify';
import { cognitoUserPoolsTokenProvider } from 'aws-amplify/auth/cognito';
import { CookieStorage } from 'aws-amplify/utils';

import { observer } from "mobx-react-lite"
import { Routes, Route } from "react-router-dom";
import Wallet from './mobx/Wallet';
import Tokens from './mobx/Tokens';
import ApiData from './mobx/ApiData';
import { WalletContext, TokensContext, ApiDataContext, ManagerContext } from './Components/Contexts';
import LoginPage from './Pages/LoginPage';
import LayoutPage from './Pages/LayoutPage';
import Api from './Services/Api';
import BigNumber from "bignumber.js";
import manager from './mobx/Manager';
import { MetaMaskProvider } from "metamask-react";
import { ProtectedRoute } from './Components/ProtectedRoute';


Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId: 'us-east-1_wsAlWQP1F',
      userPoolClientId: '46hnjppk1khjhrtq697p8n9usi',
      allowGuestAccess: false,
      identityPoolId: '',
    }
  }
});
cognitoUserPoolsTokenProvider.setKeyValueStorage(new CookieStorage());


BigNumber.config({ EXPONENTIAL_AT: 40 })


const App = observer(() => {
  const [wallet] = useState(() => new Wallet())
  const [tokens] = useState(() => new Tokens())
  const [apiData] = useState(() => new ApiData())
  const [managerState] = useState(() => manager)

  useEffect(() => {
    async function getTokens() {
      let response = await Api.getTokens(manager.network, manager.platform);
      tokens.setTokens(response.data.list);
    }
    getTokens();
  }, [tokens]);

  useEffect(() => {
    async function getVersion() {
      let response = await Api.getVersion();
      apiData.setData(response.data);
    }
    getVersion();
  }, [apiData]);

  return (
    <WalletContext.Provider value={wallet}>
      <TokensContext.Provider value={tokens}>
        <ApiDataContext.Provider value={apiData}>
          <ManagerContext.Provider value={managerState}>
            <MetaMaskProvider>
              <Routes>
                <Route path="/login" element={<LoginPage />}></Route>
                <Route path="*" element={<ProtectedRoute><LayoutPage /></ProtectedRoute>}></Route>
              </Routes>
            </MetaMaskProvider>
          </ManagerContext.Provider>
        </ApiDataContext.Provider>
      </TokensContext.Provider>
    </WalletContext.Provider>
  );
});

export default App;
