import { Menu, Group, Center, Burger, Container, Anchor } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconChevronDown } from '@tabler/icons-react';
import classes from './HeaderMenu.module.css';
import Logo from '../Logo';
import UserMenu from '../UserMenu';
import ProviderSelector from '../ProviderSelector';
import { Link } from 'react-router-dom';

const links = [
  { link: '/', label: 'Home' },
  {
    link: '#1',
    label: 'DEX',
    links: [
      { link: '/exchanges', label: 'Exchanges' },
      { link: '/networks', label: 'Networks' },
      { link: '/indexers', label: 'Indexers' },
      { link: '/tokens', label: 'Tokens' },
    ],
  },
  { link: '/settings', label: 'Settings' },
];

export function HeaderMenu() {
  const [opened, { toggle }] = useDisclosure(false);

  const items = links.map((link, i) => {
    const menuItems = link.links?.map((item) => (
      <Menu.Item key={item.link} component={Link} to={item.link}>{item.label}</Menu.Item>
    ));

    if (menuItems) {
      return (
        <Menu key={link.link} trigger="hover" transitionProps={{ exitDuration: 0 }} withinPortal>
          <Menu.Target>
            <a
              href={link.link}
              className={classes.link}
              onClick={(event) => event.preventDefault()}
            >
              <Center>
                <span className={classes.linkLabel}>{link.label}</span>
                <IconChevronDown size="0.9rem" stroke={1.5} />
              </Center>
            </a>
          </Menu.Target>
          <Menu.Dropdown>{menuItems}</Menu.Dropdown>
        </Menu>
      );
    }

    return (
      <Anchor
        key={link.link}
        to={link.link}
        component={Link}
        className={classes.link}
      >
        {link.label}
      </Anchor>
    );
  });

  return (
    <header className={classes.header}>
      <Container size="md" mt={10}>
        <div className={classes.inner}>
          <Logo />
          <Group gap={5} visibleFrom="sm">
            {items}
          </Group>
          <ProviderSelector />
          <UserMenu />

          <Burger opened={opened} onClick={toggle} size="sm" hiddenFrom="sm" />
        </div>
      </Container>
    </header>
  );
}