import React, { useEffect, useState } from "react";
import { Center, Grid, Button, Loader, Notification } from '@mantine/core';
import JSONInput from 'react-json-editor-ajrm';
import Api from '../Services/Api';
import { Refresh } from 'tabler-icons-react';

const locale = require('react-json-editor-ajrm/locale/en');


export default function SettingsPage() {
  const [json, setJSON] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const [loading, setLoading] = useState(false);

  const loadSettings = async () => {
    setLoading(true);
    try {
      let response = await Api.getSettings();
      setJSON(response.data);
      setErrorMessage("");
    } catch (e) {
      setErrorMessage("Can not load settings. Please try again later.")
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadSettings();
  }, []);

  const onReloading = async () => {
    await loadSettings();
  };

  const onSave = async () => {
    setIsSaving(true);
    try {
      let response = await Api.saveSettings(json);
      setJSON(response.data);
    } catch (e) {
      setErrorMessage("Can not save settings. Please try again later.")
    } finally {
      setIsSaving(false);
    };
  };

  const setValue = (value: any) => {
    if (value.error) {
      setErrorMessage("Error on JSON");
    } else {
      setJSON(value.jsObject);
    }
  };

  return (
    <div>
      <h2>Settings</h2>
      {loading ? (
        <Center style={{ marginTop: "50px" }}><Loader /></Center>
      ) : (
        <>
          {errorMessage &&
            <Notification color="red">{errorMessage}</Notification>
          }

          <Grid style={{ width: "100%" }}>
            <Grid.Col span={5}>
              <Button onClick={onSave} loading={isSaving}>Save</Button>
            </Grid.Col>
            <Grid.Col span={5}>
              <Button onClick={onReloading} loading={loading}><Refresh /></Button>
            </Grid.Col>
          </Grid>

          <JSONInput
            id="json"
            placeholder={json}
            height="600px"
            locale={locale}
            onChange={setValue}
          />
        </>
      )}
    </div>
  );
}
