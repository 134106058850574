import React, { useState, useEffect } from 'react';
import { Button, Grid, useMantineTheme, Container, Center, Box, Loader, MultiSelect, InputWrapper, Notification} from '@mantine/core';
import { useColorScheme } from '@mantine/hooks';
import Api from '../Services/Api';
import { LiquidationResponse, LiquidationModel } from './Models'
import MyPagination from './MyPagination';
import { Refresh } from 'tabler-icons-react';
import LiqDialog from './LiqDialog';
import LiquidationItem from './LiquidationItem';
import { useSearchParams } from "react-router-dom";
import { TokensContext, ManagerContext } from './Contexts';

const PER_PAGE = 30;


export default function LiquidationTable() {
  const theme = useMantineTheme();
  const colorScheme = useColorScheme();
  const [errorMessage, setErrorMessage] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [offset, setOffset] = useState(parseInt(searchParams.get("offset") || "0"));
  const [fromTokens, setFromTokens] = useState<string[]>(searchParams.get("from_tokens")?.split(",").filter(token => token.length > 0) || []);
  const [toTokens, setToTokens] = useState<string[]>(searchParams.get("to_tokens")?.split(",").filter(token => token.length > 0) || []);
  const [maxAmountUsd] = useState(parseInt(searchParams.get("maxAmountUsd") || "10000000"));
  const [minAmountUsd] = useState(parseInt(searchParams.get("minAmountUsd") || "1"));
  const tokens = React.useContext(TokensContext);
  const manager = React.useContext(ManagerContext);

  const [isLoadingLiq, setIsLoadingLiq] = useState(true);

  const [liqs, setLiqs] = useState<LiquidationResponse | null>(null);

  const [opened, setOpened] = useState(false);
  const [currentLiquidation, setCurrentLiquidation] = useState<LiquidationModel | null>(null);
  const loadLiquidations = async (offset: number, limit: number, fromTokens: string[], toTokens: string[], maxAmountUsd: number, minAmountUsd: number) => {
    setLiqs(null);
    try {
      let response = await Api.getLiquidations(offset, limit, fromTokens, toTokens, maxAmountUsd, minAmountUsd);
      setLiqs(response.data)
      setOffset(response.data.pagination.offset);
      setErrorMessage("");
    } catch (e) {
      setErrorMessage("Can not load liquidations. Please try again later.");
    } finally {
      setIsLoadingLiq(false);
    }
  }
  useEffect(() => {
    loadLiquidations(offset, PER_PAGE, fromTokens, toTokens, maxAmountUsd, minAmountUsd);
  }, [offset, fromTokens, toTokens, maxAmountUsd, minAmountUsd, manager]);

  const onPageChange = (page: number) => {
    setOffset((page - 1) * PER_PAGE);
    setSearchParams({
      "from_tokens": fromTokens.join(","),
      "to_tokens": toTokens.join(","),
      "offset": ((page - 1) * PER_PAGE).toString(),
      "maxAmountUsd": maxAmountUsd.toString(),
      "minAmountUsd": minAmountUsd.toPrecision()
    });
  };

  const onChangeFromTokens = (value: string[]) => {
    setFromTokens(value);
    setOffset(0);
    setSearchParams({
      "from_tokens": value.join(","),
      "to_tokens": toTokens.join(","),
      "offset": "0",
      "maxAmountUsd": maxAmountUsd.toString(),
      "minAmountUsd": minAmountUsd.toPrecision()
    });
  }

  const onChangeToTokens = (value: string[]) => {
    setToTokens(value);
    setOffset(0);
    setSearchParams({
      "from_tokens": fromTokens.join(","),
      "to_tokens": value.join(","),
      "offset": "0",
      "maxAmountUsd": maxAmountUsd.toString(),
      "minAmountUsd": minAmountUsd.toPrecision()
    });
  }

  const refreshLiquidations = async () => {
    setIsLoadingLiq(true);
    await loadLiquidations(offset, PER_PAGE, fromTokens, toTokens, maxAmountUsd, minAmountUsd)
  }

  const openLiquidation = (liquidation: LiquidationModel) => {
    setCurrentLiquidation(liquidation);
    setOpened(true);
  }

  const onClose = (should_refresh: boolean) => {
    setOpened(false);
    setCurrentLiquidation(null);
    if (should_refresh) {
      refreshLiquidations();
    }
  }

  return (
    <Center>
      { currentLiquidation &&
        <LiqDialog opened={opened} onClose={onClose} currentLiquidation={currentLiquidation}></LiqDialog>
      }
      <Container style={{width: "100%"}}>
        <Grid style={{width: "100%"}}>
          <Grid.Col span={5}>
            <InputWrapper label="From tokens">
              <MultiSelect value={fromTokens} data={tokens.tokens.map((t) => t.symbol)} onChange={onChangeFromTokens} />
            </InputWrapper>
          </Grid.Col>
          <Grid.Col span={5}>
            <InputWrapper label="To tokens">
              <MultiSelect value={toTokens} data={tokens.poolTokens.map((t) => t.symbol)} onChange={onChangeToTokens} />
            </InputWrapper>
          </Grid.Col>
          <Grid.Col span={1}>
            <InputWrapper label=" ">
              <Button onClick={refreshLiquidations} disabled={isLoadingLiq} ><Refresh /></Button>
            </InputWrapper>
          </Grid.Col>
        </Grid>

        <Box style={{
            marginTop: "10px",
            marginBottom: "10px",
            backgroundColor: colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
            textAlign: 'center',
            verticalAlign: 'middle',
            padding: theme.spacing.xs,
            borderRadius: theme.radius.md,
          }}>
          <Grid>
            <Grid.Col span={1}>#</Grid.Col>
            <Grid.Col span={2}><b>Amount, $</b></Grid.Col>
            <Grid.Col span={2}><b>Direction</b></Grid.Col>
            <Grid.Col span={2}><b>Borrower</b></Grid.Col>
          </Grid>
        </Box>

        {liqs?.liquidations.length === 0 &&
          <Center><h2>No results</h2></Center>
        }

        {errorMessage &&
          <Notification color="red">{errorMessage}</Notification>
        }

        {isLoadingLiq
          ? <Center style={{marginTop: "50px"}}><Loader /></Center>
          :
          <>
            {liqs?.liquidations.map((liq, i) =>
              <LiquidationItem key={"liq" + i} liq={liq} num={offset + i + 1} openLiquidation={openLiquidation} />
            )}
            <MyPagination pagination={liqs?.pagination} onChange={onPageChange} />
          </>
        }
      </Container>
    </Center>
  )
}
