import React from 'react';
import { Pagination } from '@mantine/core';
import { PaginationModel } from './Models';


export default function MyPagination({pagination, onChange}: {pagination: PaginationModel | undefined, onChange: any}) {
  if (!pagination) {
    return null;
  }
  const total = Math.ceil(pagination.total / pagination.limit);
  const current = pagination.offset / pagination.limit + 1;
  return (
    <Pagination value={current} total={total} onChange={onChange} />
  )
}
