import { Anchor } from '@mantine/core';
import Tokens from '../../mobx/Tokens';
import Wallet from '../../mobx/Wallet';
import BigNumber from "bignumber.js";
import { LiquidationModel } from '../../Components/Models';
import Api from '../Api';
import BaseProvider from '../Provider';
import Comptroller from './Comtroller';
import { notifications } from '@mantine/notifications';
import PoolContract from './PoolContract';


const delay = (ms: number) => new Promise(res => setTimeout(res, ms));


export default class CompoundV2Provider extends BaseProvider {
  constructor() {
    super("eth", "compound_v2")
  }

  public async liquidate(setCurrentAction: any, onClose: any, tokens: Tokens, wallet: Wallet, currentLiquidation: LiquidationModel, fromTokenAmount: BigNumber, toTokenAmount: BigNumber) {
    setCurrentAction(0);
    const poolToken = tokens.getPoolToken(currentLiquidation!.from_amount.token);

    const shortfall = await Comptroller.getAccountLiquidity(currentLiquidation.borrower);
    if (shortfall.isZero()) {
      notifications.show({
        title: "Failed to run liquidation",
        message: "Liquidation for this borrow does not work at the moment",
        autoClose: true,
        color: "red"
      });
      return;
    }

    const assets = await Comptroller.getAssetsIn(currentLiquidation.borrower);
    if (assets.indexOf(currentLiquidation.to_amount.token.address) === -1) {
      notifications.show({
        title: "Failed to run liquidation",
        message: "Token is not authorized as collateral",
        autoClose: true,
        color: "red"
      });
      return;
    }

    let toContract = await PoolContract.getContract(currentLiquidation.to_amount.token.address)
    console.log("to ", currentLiquidation.to_amount.token.address, " borrower ", currentLiquidation.borrower)
    const balance = await toContract.balanceOf(currentLiquidation.borrower);
    if (balance.comparedTo(toTokenAmount) === -1) { // balance < toTokenAmount
      notifications.show({
        title: "Failed to run liquidation",
        message: "Collateral balance is not enough",
        autoClose: true,
        color: "red"
      });
      return;
    }

    setCurrentAction(1);
    if (currentLiquidation.from_amount.token.symbol !== "ETH") {
      let fromContract = await PoolContract.getContract(currentLiquidation.from_amount.token.address);
      const balanceFromAmount = await fromContract.balanceOf(wallet.address);
      if (balanceFromAmount.comparedTo(fromTokenAmount) === -1) {  // balanceFromAmount < fromTokenAmount
        notifications.show({
          title: "Failed to run liquidation",
          message: "You do not have enough tokens",
          autoClose: true,
          color: "red"
        });
        return;
      }

      const balanceAllowance = await fromContract.allowance(wallet.address, poolToken.address);
      if (balanceAllowance.comparedTo(fromTokenAmount) === -1) { // balanceAllowance < fromTokenAmount
        const approveTransId = await fromContract.approve(poolToken.address, "115792089237316195423570985008687907853269984665640564039457584007913129639935");
        notifications.show({
          title: "Approve was executed",
          message: <Anchor target="_blank" href={"https://etherscan.io/tx/" + approveTransId}>Open Transaction</Anchor>,
          autoClose: true,
          color: "green"
        });
        await delay(5000);
      }
    }

    setCurrentAction(2);
    let poolContract = await PoolContract.getContract(poolToken.address)
    const transId = await poolContract.liquidate(currentLiquidation.borrower, currentLiquidation.to_amount.token.address, fromTokenAmount);
    notifications.show({
      title: "Liquidation was executed",
      message: <Anchor target="_blank" href={"https://etherscan.io/tx/" + transId}>Open Transaction</Anchor>,
      autoClose: true,
      color: "green"
    });

    await delay(10000);

    setCurrentAction(3);

    await this.refresh(currentLiquidation);
    onClose(true);
  }

  public async refresh(currentLiquidation: LiquidationModel) {
    await Api.refreshBorrower(currentLiquidation.borrower);
  }

  public async getToTokens(tokens: Tokens, currentLiquidation: LiquidationModel, tokenAmount: BigNumber): Promise<BigNumber> {
    const poolToken = tokens.getPoolToken(currentLiquidation.from_amount.token);
    const toTokens = await Comptroller.liquidateCalculateSeizeTokens(poolToken.address, currentLiquidation.to_amount.token.address, tokenAmount);
    return toTokens;
  }

  public async connectWallet(wallet: Wallet): Promise<[string, string]> {
    const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
    if (accounts.length > 0) {
      wallet.setAddress(accounts[0]);
    }
    return ["", ""];
  }

  public async checkWallet(wallet: Wallet) {
  }
}
