interface ExplorerLinkProps {
  address: string;
  category: string;
  network: string;
  short?: boolean;
}


const ExplorerLink = (props: ExplorerLinkProps) => {
  if (!props.address) {
    return null;
  }

  let formatted_address;
  if (props.short) {
    formatted_address = props.address.slice(0, 6) + '...' + props.address.slice(-4);
  } else {
    formatted_address = props.address;
  }

  let link;
  if (props.network === "tron") {
    link = "https://tronscan.org/#/";
    if (props.category === "address") {
      link += `address/${props.address}`;
    }
  } else if (props.network === "arbitrum") {
    link = "https://arbiscan.io/";
    if (props.category === "address") {
      link += `address/${props.address}`;
    }
  }

  return <a target="_blank" rel="noreferrer" href={link}>{formatted_address}</a>
}

export default ExplorerLink;