import { makeAutoObservable } from "mobx"

class Wallet {
  address = "";

  constructor() {
    makeAutoObservable(this)
  }

  setAddress = (address: string) => {
    this.address = address;
  }
}

export default Wallet;
