import { useState, useEffect } from 'react';
import { Alert, Center, LoadingOverlay, Badge, TextInput, PasswordInput, Paper, Title, Container, Group, Button } from '@mantine/core';
import { signIn, signOut, getCurrentUser } from 'aws-amplify/auth';
import { AlertCircle } from 'tabler-icons-react';


const LoginPage = () => {
  const [loginusername, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState<string | null>(null);

  useEffect(() => {
    async function getUser() {
      try {
        const { userId } = await getCurrentUser();
        setUser(userId);
        console.log(userId);
      } catch (error) {
        console.log('error getting user', error);
        setUser("");
      }
    }
    getUser();
  } , [user]);

  const onChangeUsername = (e: any) => {
    setUsername(e.target.value);
  };

  const onChangePassword = (e: any) => {
    setPassword(e.target.value);
  };

  const OnSignIn = async() => {
    try {
      setIsLoading(true);
      const { isSignedIn } = await signIn({
        username: loginusername,
        password: password
      });
      if (isSignedIn) {
        setUser(null);
      }
    } catch (error) {
      setError("Wrong username or password. Sorry can not login.");
      console.log('error signing in', error);
    } finally {
      setIsLoading(false);
    }
  };

  const OnSignOut = async() => {
    try {
      setIsLoading(true);
      await signOut();
      setUser(null);
    } catch (error) {
      console.log('error signing out: ', error);
    } finally {
      setIsLoading(false);
    }
  }
  return (
    <Container size={420} my={40}>
      <LoadingOverlay visible={isLoading} />
      {user ? (
        <Group>
          <Title>You are logged in as <Badge>{user}</Badge>:</Title>
          <Button loading={isLoading} onClick={async () => {await OnSignOut() }} size="sm">
            Logout
          </Button>
        </Group>
      ) : (
        <>
          <Center>
            <Title>Welcome back!</Title>
          </Center>
          {!!error && <Alert mt={20} icon={<AlertCircle size={16} />} title="Login" color="red">{error}</Alert>}
          <Paper withBorder shadow="md" p={30} mt={30} radius="md">
            <TextInput label="Username" onChange={onChangeUsername} placeholder="Your username" required />
            <PasswordInput label="Password" onChange={onChangePassword} placeholder="Your password" required mt="md" />
            <Button loading={isLoading} onClick={async () => {await OnSignIn() }} fullWidth mt="xl">
              Sign in
            </Button>
          </Paper>
        </>
      )}
    </Container>
  );
};

export default LoginPage;
