import React, { useState } from 'react';
import {
  Alert,
  Box,
  Container,
  Center,
  Button,
  Grid,
} from '@mantine/core';
import { PlugConnected, AlertCircle } from 'tabler-icons-react';
import { WalletContext, ManagerContext } from './Contexts';

export default function Connector() {
  const [myMessage, setMyMessage] = useState("");
  const [color, setColor] = useState("");
  const wallet = React.useContext(WalletContext);
  const manager = React.useContext(ManagerContext);
  const provider = manager.getCurrentProvider();

  const connectWallet = async () => {
    setMyMessage("WAITING FOR RESPONSE");
    setColor("yellow");
    const [curcolor, curmessage] = await provider.connectWallet(wallet);
    if (curcolor) {
      setColor(curcolor);
    }
    if (curmessage) {
      setMyMessage(curmessage);
    }
  };

  const checkWallet = async () => {
    await provider.checkWallet(wallet);
  };

  setInterval(async () => {
    await checkWallet();
  }, 1000);

  return (
    <Container style={{ height: "100%" }}>
      <Grid style={{ height: "100%" }}>
        <Grid.Col span={12} style={{ verticalAlign: "bottom"}}>
          { myMessage
            ? <Box><Alert color={color} icon={<AlertCircle size={16} />}>{myMessage}</Alert></Box>
            : " "
          }
        </Grid.Col>
        <Grid.Col span={12}>
          <Center>
            <Button
              leftSection={<PlugConnected />}
              onClick={connectWallet}
              variant="gradient"
              gradient={{ from: 'indigo', to: 'cyan' }}
            >Connect {provider.network} wallet</Button>
          </Center>
        </Grid.Col>
      </Grid>
    </Container>
  )
}
