
import React from 'react';

import { Select, Flex } from '@mantine/core';
import { ManagerContext } from './Contexts';


export default function ProviderSelector() {
  const manager = React.useContext(ManagerContext);

  let data: any[] = [];
  for (const provider of manager.providers) {
    data.push({
      label: `${provider.network} ${provider.platform}`,
      value: `${provider.network}-${provider.platform}`
    })
  }

  const setProvider = (value: any) => {
    const event = value.split("-");
    manager.setCurrentProvider(event[0], event[1]);
  };

  return (
    <Flex ml={10}>
      <Select
        data={data}
        placeholder={`${manager.getCurrentProvider().network} ${manager.getCurrentProvider().platform}`}
        onChange={setProvider}
      />
    </Flex>
  );
}