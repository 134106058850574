import React, { useState, useEffect } from 'react';
import { Text, Button, Grid, Center, Badge, Modal, Alert, InputWrapper, NumberInput, Group, Loader, Timeline } from '@mantine/core';
import { AlertCircle, ArrowBigRight, Equal, Check } from 'tabler-icons-react';
import { LiquidationModel } from './Models';
import BigNumber from "bignumber.js";
import { TokensContext, WalletContext, ManagerContext } from './Contexts';
import Tokens from '../mobx/Tokens';
import { notifications } from '@mantine/notifications';


export default function LiqDialog({ opened, onClose, currentLiquidation }: { opened: boolean, onClose: any, currentLiquidation: LiquidationModel }) {
  const [isLoading, setIsLoading] = useState(false);
  const [currentAction, setCurrentAction] = useState<number | null>(null);
  const [isLiquidating, setIsLiquidating] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [fromTokenAmount, setFromTokenAmount] = useState<BigNumber>(new BigNumber(currentLiquidation.from_amount.amount));
  const [toTokenAmount, setToTokenAmount] = useState<BigNumber>(new BigNumber(0));
  const wallet = React.useContext(WalletContext);
  const tokens = React.useContext(TokensContext);
  const manager = React.useContext(ManagerContext);
  const provider = manager.getCurrentProvider();

  const onChangeFromAmount = async (value: number | string) => {
    value = parseFloat(value.toString());
    if (Number.isFinite(value)) {
      const tokenAmount = new BigNumber(Math.trunc(value * (10 ** currentLiquidation.from_amount.token.digits)));
      setFromTokenAmount(tokenAmount);
    }
  }

  const doAutoLiquidate = async () => {
    setIsLiquidating(true);
    try {
      await provider.autoLiquidate(setCurrentAction, onClose, tokens, wallet, currentLiquidation, fromTokenAmount, toTokenAmount)
    } catch (error) {
      let errorMessage = "Failed to run transaction";
      if (error instanceof Error) {
        errorMessage = error.toString();
      }
      notifications.show({
        title: 'Error occured',
        message: errorMessage,
        autoClose: true,
        color: "red"
      });
      throw error;
    } finally {
      setIsLiquidating(false);
      setCurrentAction(null);
    }
  };

  const doLiquidate = async () => {
    setIsLiquidating(true);
    try {
      await provider.liquidate(setCurrentAction, onClose, tokens, wallet, currentLiquidation, fromTokenAmount, toTokenAmount)
    } catch (error) {
      let errorMessage = "Failed to run transaction";
      if (error instanceof Error) {
        errorMessage = error.toString();
      }
      notifications.show({
        title: 'Error occured',
        message: errorMessage,
        autoClose: true,
        color: "red"
      });
      throw error;
    } finally {
      setIsLiquidating(false);
      setCurrentAction(null);
    }
  }

  const doRefersh = async () => {
    setIsRefreshing(true);
    try {
      await provider.refresh(currentLiquidation);
    } finally {
      setIsRefreshing(false);
    }
  };

  useEffect(() => {
    const doToTokens = async(fromTokenAmount: BigNumber, currentLiquidation: LiquidationModel, tokens: Tokens) => {
      const toTokens = await provider.getToTokens(tokens, currentLiquidation, fromTokenAmount);
      setToTokenAmount(toTokens);
    }

    setIsLoading(true);
    doToTokens(fromTokenAmount, currentLiquidation, tokens).finally(() => {
      setIsLoading(false);
    });
  }, [fromTokenAmount, currentLiquidation, tokens, provider]);

  return (
    <Modal
      centered
      opened={opened}
      onClose={() => onClose(false)}
      title={"Liquidate - " + currentLiquidation.borrower}
      size="600px"
    >
      <Alert style={{ margin: "10px" }} icon={<AlertCircle size={32} />} title="WARNING!!! NOT SAFE OPERATION!!!" color="red">
        You are going to run smartcontract to liqudate borrow.
      </Alert>
      <Grid>
        <Grid.Col span={5}>
          <Center>
            <Badge size="xl">{currentLiquidation.from_amount.token.symbol}</Badge>
          </Center>
          <InputWrapper size="xs" label={`Amount of ${currentLiquidation.from_amount.token.symbol} tokens`}>
            <NumberInput
              decimalScale={currentLiquidation.from_amount.token.digits}
              value={fromTokenAmount.dividedBy(10 ** currentLiquidation.from_amount.token.digits).toNumber()}
              max={currentLiquidation.from_amount.amount_normalized}
              onChange={onChangeFromAmount}
              min={0} />
          </InputWrapper>
          <Center>
            <Equal />
          </Center>
          <Center>
            <h3>
              {fromTokenAmount.dividedBy(10 ** currentLiquidation.from_amount.token.digits).multipliedBy(currentLiquidation.from_amount.token.price_usd).toNumber().toFixed(2)}&nbsp;USD
            </h3>
          </Center>
        </Grid.Col>
        <Grid.Col span={2}>
          <Center>
            <ArrowBigRight />
          </Center>
        </Grid.Col>
        <Grid.Col span={5}>
          <Center>
            <Badge size="xl">{currentLiquidation.to_amount.token.symbol}</Badge>
          </Center>

          {isLoading
            ? <Center style={{marginTop: "50px"}}><Loader /></Center>
            :
            <>
              <InputWrapper size="xs" label={`You will get`}>
                <NumberInput
                  decimalScale={currentLiquidation.to_amount.token.digits}
                  value={toTokenAmount.dividedBy(10 ** currentLiquidation.to_amount.token.digits).toNumber()}
                  max={currentLiquidation.to_amount.amount_normalized}
                  disabled
                />
              </InputWrapper>
              <Center>
                <Equal />
              </Center>
              <Center>
                <h3>
                  {toTokenAmount.dividedBy(10 ** currentLiquidation.to_amount.token.digits).multipliedBy(currentLiquidation.to_amount.token.price_usd).toNumber().toFixed(2)}&nbsp;USD
                </h3>
              </Center>
            </>
          }

        </Grid.Col>
      </Grid>

      { (currentAction !== null) &&
        <Center style={{marginTop: "20px", marginBottom: "20px"}}>
          <Timeline active={currentAction} bulletSize={24} lineWidth={2}>
            <Timeline.Item bullet={<Check size={12} />} title="Validate">
              <Text c="dimmed" size="xs">Validate we can run liquidation</Text>
            </Timeline.Item>
            <Timeline.Item bullet={<Check size={12} />} title="Approve">
              <Text c="dimmed" size="xs">Approve token to spend</Text>
            </Timeline.Item>
            <Timeline.Item bullet={<Check size={12} />} title="Liquidate">
              <Text c="dimmed" size="xs">Run liquidation transaction</Text>
            </Timeline.Item>
            <Timeline.Item bullet={<Check size={12} />} title="Refresh data">
              <Text c="dimmed" size="xs">Waiting for transaction to end and refresh data</Text>
            </Timeline.Item>
          </Timeline>
        </Center>
      }

      <Center>
        <Group style={{ margin: "20px" }}>
          <Button
            loading={isLiquidating}
            onClick={doLiquidate}
            >Liquidate</Button>
          <Button
            color="green"
            loading={isLiquidating}
            onClick={doAutoLiquidate}
            >Auto</Button>
          <Button
            loading={isRefreshing}
            onClick={doRefersh}
            >Refresh</Button>
        </Group>
      </Center>
    </Modal>
  )
}
