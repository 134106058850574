import { Text, Flex } from '@mantine/core';
import { Link } from 'react-router-dom';

import { ThemeIcon } from '@mantine/core';
import { Recycle } from 'tabler-icons-react';


const Logo = () => {
  return (
    <Flex>
      <ThemeIcon variant="outline">
        <Recycle />
      </ThemeIcon>
      &nbsp;
      <Text size="xl" component={Link} to="/">Liquida<b>THor</b></Text>
    </Flex>
  );
}

export default Logo;