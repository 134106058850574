import { makeAutoObservable } from "mobx"
import { TokenModel } from '../Components/Models';
import manager from './Manager';

class Tokens {
  allTokens: TokenModel[] = [];
  tokens: TokenModel[] = [];
  poolTokens: TokenModel[] = [];
  tokenMapping: { [key: string]: TokenModel } = {};

  constructor() {
    makeAutoObservable(this)
  }

  setTokens = (tokens: TokenModel[]) => {
    this.allTokens = tokens;
    this.tokens = this.getTokens(this.allTokens, false);
    this.poolTokens = this.getTokens(this.allTokens, true);
    for (let i in this.allTokens) {
      const token = this.allTokens[i];
      this.tokenMapping[token.symbol] = token;
    }
  }

  private getTokens(allTokens: TokenModel[], is_pool: boolean): TokenModel[] {
    let result: TokenModel[] = [];
    for (let i in allTokens) {
      let token = allTokens[i];
      if (token.is_pool === is_pool) {
        result.push(token);
      }
    }
    return result;
  }

  public getPoolToken(token: TokenModel): TokenModel {
    if (token.is_pool) {
      return token;
    }
    if ((manager.network === 'eth') && (manager.platform === 'compound_v2')) {
      return this.tokenMapping["c" + token.symbol];
    }
    return this.tokenMapping["j" + token.symbol];
  }

  public getCommonToken(token: TokenModel): TokenModel {
    if (!token.is_pool) {
      return token;
    }
    return this.tokenMapping[token.symbol.slice(1)];
  }

  public getToken(token: TokenModel): TokenModel {
    return this.tokenMapping[token.symbol];
  }
}

export default Tokens;
