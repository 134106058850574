import { AppShell, Container, useMantineTheme } from '@mantine/core';
import { useColorScheme } from '@mantine/hooks';

import { Routes, Route } from "react-router-dom";
import SettingsPage from '../Pages/SettingsPage';
import HomePage from '../Pages/HomePage';
import { ProtectedRoute } from '../Components/ProtectedRoute';
import NetworksPage from './NetworksPage';
import ExchangesPage from './ExchangePage';
import { HeaderMenu } from '../Components/HeaderMenu/HeaderMenu';
import IndexersPage from './IndexersPage';
import TokensPage from './TokensPage';


const LayoutPage = () => {
  const theme = useMantineTheme();
  const colorScheme = useColorScheme();

  return (
    <AppShell
      styles={{
        main: {
          background: colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0],
        },
      }}
      header={{ height: 60 }}
    >
      <AppShell.Header>
        <HeaderMenu />
      </AppShell.Header>

      <AppShell.Main>
        <Container style={{ height: "100%", paddingTop: "10px", paddingBottom: "20px" }}>
          <Routes>
            <Route path="/" element={<ProtectedRoute><HomePage /></ProtectedRoute>}></Route>
            <Route path="/networks" element={<ProtectedRoute><NetworksPage /></ProtectedRoute>}></Route>
            <Route path="/tokens" element={<ProtectedRoute><TokensPage /></ProtectedRoute>}></Route>
            <Route path="/exchanges" element={<ProtectedRoute><ExchangesPage /></ProtectedRoute>}></Route>
            <Route path="/indexers" element={<ProtectedRoute><IndexersPage /></ProtectedRoute>}></Route>
            <Route path="/settings" element={<ProtectedRoute><SettingsPage /></ProtectedRoute>}></Route>
          </Routes>
        </Container>
      </AppShell.Main>
    </AppShell>
  )
}

export default LayoutPage;
