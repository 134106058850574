import { useEffect, useState } from 'react';
import { Box, Group, Button, Flex, MultiSelect, Anchor, TextInput } from '@mantine/core';
import { DataTable } from 'mantine-datatable';
import Api from '../Services/Api';
import { useSearchParams } from "react-router-dom";
import { IconSearch } from '@tabler/icons-react';


const TokensPage = () => {
  const PER_PAGE = 100;
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [fetching, setFetching] = useState(false);
  const [records, setRecords] = useState<any>({ list: [], total: 0 });
  const [filters, setFilters] = useState<any>({});
  let [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    let filters: any = {}
    let networks = searchParams.get('networks');
    if (networks) {
      filters.networks = networks.split(',');
    }
    setFilters(filters);
  }, [searchParams]);


  const onChangeNetworks = (value: any) => {
    let newFilters = {
      ...filters,
    };
    setFilters(newFilters);
    setSearchParams({
      networks: value.join(','),
    });
  };

  const loadRecords = async (page: number, search: string) => {
    setFetching(true);
    try {
      let response = await Api.getTokens(null, null, search, PER_PAGE, (page - 1) * PER_PAGE);
      setRecords(response.data);
    } finally {
      setFetching(false);
    }
  };

  useEffect(() => {
    async function getToken() {
      await loadRecords(page, search);
    }
    getToken();
  }, [page, search]);

  const onReload = async () => {
    await loadRecords(page, search);
  };

  const onSearchKeyDown = async (event: any) => {
    if (event.key === 'Enter') {
      setSearch(event.target.value);
      setPage(1);
    }
  };

  let networks: string[] = ["tron", "arbitrum", "ethereum"];

  return (
    <div>
      <Flex mb={10} direction={"row"} justify={"space-between"} align={"center"}>
        <h3>Tokens</h3>
        <Flex gap={20}>
          <Button loading={fetching} variant="danger" onClick={onReload}>Reload</Button>
        </Flex>
      </Flex>

      <TextInput label="Search..." my={20} onKeyDown={onSearchKeyDown} />

      <DataTable
        withTableBorder
        withColumnBorders
        striped
        highlightOnHover
        records={records.list}
        minHeight={100}
        idAccessor="address"
        totalRecords={records.count}
        page={page}
        onPageChange={(p) => setPage(p)}
        recordsPerPage={PER_PAGE}
        columns={[
          {
            accessor: 'address',
            title: 'Address',
            render: (row: any) => (
              <Group gap={4} align="center">
                {row.url ? (
                  <Anchor href={row.url} target='_blank'>{row.address}</Anchor>
                ) : (
                  row.address
                )}
              </Group>
            ),
          },
          {
            accessor: 'symbol',
            title: 'Symbol',
          },
          {
            accessor: 'digits',
            title: 'Digits',
          },
          {
            accessor: 'network',
            title: 'Network',
            filtering: (filters.networks || []).length > 0,
            filter: (
              <MultiSelect
                label="Networks"
                data={networks}
                value={filters.networks || []}
                placeholder="Search networks..."
                onChange={onChangeNetworks}
                leftSection={<IconSearch size={16} />}
                clearable
                searchable
              />
            ),

          },
          {
            accessor: 'platform',
            title: 'Platform',
          },
          {
            accessor: 'actions',
            width: '0%',
            title: <Box mx={6}>Row actions</Box>,
            textAlign: 'right',
            render: (row: any) => (
              <Group gap={4} justify="right" wrap="nowrap">

              </Group>
            ),
          },
        ]}
        fetching={fetching}
      />
    </div>
  );
}

export default TokensPage;