import React from 'react';
import { Anchor, Grid, Box, Badge, useMantineTheme } from '@mantine/core';
import { LiquidationModel } from './Models';
import { useColorScheme } from '@mantine/hooks';


export default function LiquidationItem({ liq, num, openLiquidation }: { liq: LiquidationModel, num: number, openLiquidation: any }) {
  const theme = useMantineTheme();
  const colorScheme = useColorScheme();
  return (
    <Box onClick={() => openLiquidation(liq)} style={{
      marginTop: "10px",
      marginBottom: "10px",
      backgroundColor: colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
      textAlign: 'center',
      verticalAlign: 'middle',
      padding: theme.spacing.md,
      borderRadius: theme.radius.md,
      cursor: 'pointer',

      '&:hover': {
        backgroundColor:
          colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[1],
      },
    }}>
      <Grid>
        <Grid.Col span={1}>{num}</Grid.Col>
        <Grid.Col span={2}>
          <Badge color="green">{parseFloat(liq.amount_usd).toFixed(2)}&nbsp;$</Badge>
        </Grid.Col>
        <Grid.Col span={2}>
          <Badge>{liq.from_amount.token.symbol + '→' + liq.to_amount.token.symbol}</Badge>
        </Grid.Col>
        <Grid.Col span={2}>
          <Anchor href={"https://tronscan.io/#/address/" + liq.borrower} target="_blank">{liq.borrower}</Anchor>
        </Grid.Col>
      </Grid>
    </Box>
  )
}