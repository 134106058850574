import axios from 'axios';
import manager from '../mobx/Manager';
import { fetchAuthSession } from 'aws-amplify/auth';
import { notifications } from '@mantine/notifications';

const api_url = process.env.REACT_APP_API_URL || "";


const Api = {
  _request: async (method: string, url: string, data: any = null, headers: any = null) => {
    const { accessToken } = (await fetchAuthSession({forceRefresh: true})).tokens ?? {};
    headers = headers || {};
    headers["Authorization"] = `Bearer ${accessToken?.toString()}`;

    try {
      return await axios({
        method: method,
        url: api_url + url,
        data: (method.toLowerCase() === "get") ? null : data,
        params: (method.toLowerCase() === "get") ? data : null,
        headers: headers
      })
    } catch (error: any) {
      let msg = error.toString();

      notifications.show({
        title: "Unexpected error from API",
        message: msg,
        autoClose: true,
        color: "red"
      });

      throw new Error(msg);
    }
  },

  getLiquidations: async (offset: number, limit: number, fromTokens: string[], toTokens: string[], maxAmountUsd: number, minAmountUsd: number) => {
    const data = {
      "offset": offset,
      "limit": limit,
      "from_tokens": fromTokens,
      "to_tokens": toTokens,
      "max_amount_usd": maxAmountUsd,
      "min_amount_usd": minAmountUsd
    };
    return await Api._request("get", `/${manager.network}/${manager.platform}/liquidations/`, data);
  },

  getTokens: async (network: string | null, platform: string | null, search: string = "", limit: number = 100, offset: number = 0) => {
    const data = {
      "network": network,
      "platform": platform,
      "limit": limit,
      "offset": offset,
      "search": search,
    };
    return await Api._request("get", `/tokens/`, data);
  },

  refreshBorrower: async (address: string) => {
    return await Api._request("post", `/${manager.network}/${manager.platform}/borrowers/${address}/refresh/`);
  },

  getVersion: async () => {
    return await Api._request("get", "/version/");
  },

  // Settings
  getSettings: async () => {
    return await Api._request("get", "/settings/");
  },

  saveSettings: async (data: any) => {
    return await Api._request("post", "/settings/", data);
  },

  // Networks
  getNetworks: async () => {
    return await Api._request("get", "/networks/");
  },

  addNetwork: async (name: string) => {
    return await Api._request("post", "/networks/", { name: name });
  },

  updateNetwork: async (name: string, new_name: string) => {
    return await Api._request("post", `/networks/${name}/`, { name: new_name });
  },

  deleteNetwork: async (name: string) => {
    return await Api._request("delete", `/networks/${name}/`);
  },

  // Exchanges
  getExchanges: async () => {
    return await Api._request("get", "/exchanges/");
  },

  getExchange: async (slug: string) => {
    return await Api._request("get", `/exchanges/${slug}/`);
  },

  addExchange: async (data: any) => {
    return await Api._request("post", "/exchanges/", data);
  },

  updateExchange: async (slug: string, data: any) => {
    return await Api._request("post", `/exchanges/${slug}/`, data);
  },

  deleteExchange: async (slug: string) => {
    return await Api._request("delete", `/exchanges/${slug}/`);
  },

  reloadExchangePairs: async (slug: string) => {
    return await Api._request("post", `/exchanges/${slug}/reload/`);
  },

  // indexers
  getIndexers: async () => {
    return await Api._request("get", "/indexers/");
  }
}

export default Api;
