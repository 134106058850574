import BigNumber from "bignumber.js";

const TRX_POOL_ADDRESS = "TE2RzoSV3wFK99w6J9UnnZ4vLfXYoxvRwP";

class PoolContract {
  private poolAddress: string = "";
  private contract: any;

  private constructor(poolAddress: string) {
    this.poolAddress = poolAddress;
  }

  private async loadContract() {
    this.contract = await window.tronWeb.contract().at(this.poolAddress);
  }

  public static async getContract(poolAddress: string) {
    let instance = new PoolContract(poolAddress);
    await instance.loadContract();
    return instance;
  }

  public async liquidate(borrower: string, to_token_string: string, amount: BigNumber) {
    let result;
    if (this.poolAddress === TRX_POOL_ADDRESS) {
      result = await this.contract.liquidateBorrow(borrower, to_token_string).send({
        feeLimit: 200_000_000,
        callValue: amount.toString()
      });
    } else {
      result = await this.contract.liquidateBorrow(borrower, amount.toString(), to_token_string).send({
        feeLimit: 200_000_000
      });
    }
    return result;
  }

  public async balanceOf(address: string) {
    const result = await this.contract.balanceOf(address).call()
    const balance = new BigNumber(result._hex);
    return balance;
  }

  public async allowance(owner_address: string, spender_address: string) {
    const result = await this.contract.allowance(owner_address, spender_address).call()
    const allowAmount = new BigNumber(result._hex);
    return allowAmount;
  }

  public async approve(spender_address: string, amount: string) {
    const result = await this.contract.approve(spender_address, amount.toString()).send({
      feeLimit: 50_000_000
    });
    return result;
  }
}

export default PoolContract;