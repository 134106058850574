import { useEffect, useState } from 'react';
import { Box, Group, Button, Flex, MultiSelect } from '@mantine/core';
import { DataTable } from 'mantine-datatable';
import Api from '../Services/Api';
import { useSearchParams } from "react-router-dom";
import { IconSearch } from '@tabler/icons-react';


const IndexersPage = () => {
  const [fetching, setFetching] = useState(false);
  const [records, setRecords] = useState<any[]>([]);
  const [filters, setFilters] = useState<any>({});
  let [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    let filters: any = {}
    let networks = searchParams.get('networks');
    if (networks) {
      filters.networks = networks.split(',');
    }
    setFilters(filters);
  }, [searchParams]);


  const onChangeNetworks = (value: any) => {
    let newFilters = {
      ...filters,
    };
    setFilters(newFilters);
    setSearchParams({
      networks: value.join(','),
    });
  };

  const loadRecords = async () => {
    setFetching(true);
    try {
      let response = await Api.getIndexers();
      setRecords(response.data);
    } finally {
      setFetching(false);
    }
  };

  useEffect(() => {
    async function getIndexer() {
      await loadRecords();
    }
    getIndexer();
  }, []);

  const onReload = async () => {
    await loadRecords();
  };

  let networks: string[] = [];
  for (let record of records) {
    if (record.network && !networks.includes(record.network)) {
      networks.push(record.network);
    }
  }

  let filteredRecords = records;
  if (filters.networks && filters.networks.length > 0) {
    filteredRecords = filteredRecords.filter((record) => filters.networks.includes(record.network));
  }

  return (
    <div>
      <Flex mb={10} direction={"row"} justify={"space-between"} align={"center"}>
        <h3>Indexers</h3>
        <Flex gap={20}>
          <Button loading={fetching} variant="danger" onClick={onReload}>Reload</Button>
        </Flex>
      </Flex>
      <DataTable
        withTableBorder
        withColumnBorders
        striped
        highlightOnHover
        records={filteredRecords}
        minHeight={100}
        idAccessor="name"
        columns={[
          {
            accessor: 'id',
            title: 'Id',
          },
          {
            accessor: 'slug',
            title: 'Slug',
          },
          {
            accessor: 'network',
            title: 'Network',
            filtering: (filters.networks || []).length > 0,
            filter: (
              <MultiSelect
                label="Networks"
                data={networks}
                value={filters.networks || []}
                placeholder="Search networks..."
                onChange={onChangeNetworks}
                leftSection={<IconSearch size={16} />}
                clearable
                searchable
              />
            ),

          },
          {
            accessor: 'platform',
            title: 'Platform',
          },
          {
            accessor: 'start_block',
            title: 'Start block',
          },
          {
            accessor: 'current_block_number',
            title: 'Curr block',
          },
          {
            accessor: 'actions',
            width: '0%',
            title: <Box mx={6}>Row actions</Box>,
            textAlign: 'right',
            render: (row: any) => (
              <Group gap={4} justify="right" wrap="nowrap">

              </Group>
            ),
          },
        ]}
        fetching={fetching}
      />
    </div>
  );
}

export default IndexersPage;