import { makeAutoObservable } from "mobx"

import BaseProvider from "../Services/Provider";
import { getCookie, setCookie } from 'typescript-cookie';
import TronProvider from "../Services/Justlend/TronProvider";
import Aavev1Provider from "../Services/Aave/Aavev1Provider";
import Aavev3Provider from "../Services/Aave/Aavev3Provider";
import CompoundV2Provider from "../Services/Compound/CompoundV2Provider";


class Manager {
  public providers: BaseProvider[] = [];
  public network: string;
  public platform: string;

  constructor() {
    makeAutoObservable(this);
    this.network = getCookie("network") || "";
    this.platform = getCookie("platform") || "";
  }

  registerProvider(provider: BaseProvider) {
    this.providers.push(provider);
  }

  getProvider(network: string, platform: string): BaseProvider {
    for (let provider of this.providers) {
      if ((provider.network === network) && (provider.platform === platform)) {
        return provider;
      }
    }
    throw Error("Can not find provider");
  }

  getCurrentProvider(): BaseProvider {
    try {
      return this.getProvider(this.network, this.platform);
    } catch (Error) {
      const provider: BaseProvider = this.providers[0];
      this.setCurrentProvider(provider.network, provider.platform);
      return provider;
    }
  }

  setCurrentProvider = (_network: string, _platform: string) => {
    this.getProvider(_network, _platform);
    this.network = _network;
    this.platform = _platform;
    setCookie("network", this.network, { expires: 365, path: '' });
    setCookie("platform", this.platform, { expires: 365, path: '' });
    window.location.reload();
  }
}

const manager = new Manager();
manager.registerProvider(new TronProvider());
manager.registerProvider(new Aavev1Provider());
manager.registerProvider(new Aavev3Provider());
manager.registerProvider(new CompoundV2Provider());

export default manager;