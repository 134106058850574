import { useEffect, useState } from 'react';
import { Box, Group, ActionIcon, Button, Flex, Modal, TextInput, MultiSelect, Table, Textarea } from '@mantine/core';
import { useForm } from '@mantine/form';
import { DataTable } from 'mantine-datatable';
import { useSearchParams } from "react-router-dom";
import Api from '../Services/Api';
import {
  Edit as IconEdit,
  Trash as IconTrash,
  Search as IconSearch,
  Refresh as IconRefresh,
  ScanEye as IconView,
  Key as IconKey,
} from 'tabler-icons-react';
import ExplorerLink from '../Components/ExplorerLink';


const ExchangesPage = () => {
  const [fetching, setFetching] = useState(false);
  const [records, setRecords] = useState<any[]>([]);
  const [opened, setOpened] = useState(false);
  const [viewing, setViewing] = useState<any>(null);
  const [submiting, setSubmiting] = useState(false);
  const [deleting, setDeleting] = useState("");
  const [editing, setEditing] = useState("");
  const [reloading, setReloading] = useState("");
  const [filters, setFilters] = useState<any>({});
  let [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    let filters: any = {}
    let networks = searchParams.get('networks');
    if (networks) {
      filters.networks = networks.split(',');
    }

    let categories = searchParams.get('categories');
    if (categories) {
      filters.categories = categories.split(',');
    }
    setFilters(filters);
  }, [searchParams]);

  const initial = {
    slug: '',
    network: '',
    category: '',
    factory: '',
    factory_abi: '',
    factory_params: '',
    pair_abi: '',
    router: '',
  };

  const form = useForm({
    mode: 'uncontrolled',
    initialValues: initial,
  });

  const loadRecords = async () => {
    setFetching(true);
    try {
      let response = await Api.getExchanges();
      setRecords(response.data);
    } finally {
      setFetching(false);
    }
  };

  useEffect(() => {
    async function getExchanges() {
      await loadRecords();
    }
    getExchanges();
  }, []);

  const onEditExchange = (row: any) => {
    setEditing(row.slug);
    setOpened(true);
    form.setValues(row);
  };

  const onAddNetwork = () => {
    setOpened(true);
    form.setValues(initial);
  };

  const onCloseDialog = () => {
    setOpened(false);
  };

  const onCloseViewDialog = () => {
    setViewing(null);
    setEditing("");
  };

  const onReload = async () => {
    await loadRecords();
  };

  const onRefreshExchange = async (row: any) => {
    setReloading(row.slug);
    try {
      await Api.reloadExchangePairs(row.slug);
    } finally {
      setReloading("");
    }
    await loadRecords();
  };

  const onSubmit = async (values: any) => {
    setSubmiting(true);
    try {
      if (editing) {
        await Api.updateExchange(editing, values);
      } else {
        await Api.addExchange(values)
      }
      setEditing("");
      setOpened(false);
    } finally {
      setSubmiting(false);
    }

    await loadRecords();
  };

  const onDeleteNetwork = async (slug: string) => {
    if (!window.confirm(`Are you sure you want to delete network ${slug}?`)) {
      return;
    }

    setDeleting(slug);
    try {
      await Api.deleteExchange(slug);
    } finally {
      setDeleting("");
    }

    await loadRecords();
  };

  const onViewExchange = async (row: any) => {
    let response = await Api.getExchange(row.slug);
    setViewing(response.data);
  };

  const onChangeNetworks = (value: any) => {
    let newFilters = {
      ...filters,
      networks: value,
    };
    setFilters(newFilters);
    setSearchParams({
      networks: value.join(','),
      categories: (filters.categories || []).join(','),
    });
  };

  const onChangeCategories = (value: any) => {
    let newFilters = {
      ...filters,
      categories: value,
    };
    setFilters(newFilters);
    setSearchParams({
      networks: (filters.networks || []).join(','),
      categories: value.join(','),
    });
  };

  let networks: string[] = [];
  let categories: string[] = [];
  for (let record of records) {
    if (record.network && !networks.includes(record.network)) {
      networks.push(record.network);
    }
    if (record.category && !categories.includes(record.category)) {
      categories.push(record.category);
    }
  }

  let filteredRecords = records;
  if (filters.networks && filters.networks.length > 0) {
    filteredRecords = filteredRecords.filter((record) => filters.networks.includes(record.network));
  }
  if (filters.categories && filters.categories.length > 0) {
    filteredRecords = filteredRecords.filter((record) => filters.categories.includes(record.category));
  }

  return (
    <div>
      <Modal
        centered
        opened={opened}
        onClose={onCloseDialog}
        title={"Add/edit exchange"}
        size="600px"
      >
        <form onSubmit={form.onSubmit(onSubmit)}>
          <TextInput
            withAsterisk
            label="Slug"
            placeholder="sunswap_v1"
            key={form.key('slug')}
            {...form.getInputProps('slug')}
          />

          <TextInput
            withAsterisk
            label="Network"
            placeholder="ethereum"
            key={form.key('network')}
            {...form.getInputProps('network')}
          />

          <TextInput
            withAsterisk
            label="Category"
            placeholder="uniswap_v2"
            key={form.key('category')}
            {...form.getInputProps('category')}
          />

          <TextInput
            label="Factory"
            placeholder="T..."
            key={form.key('factory')}
            {...form.getInputProps('factory')}
          />

          <Textarea
            label="Factory abi"
            placeholder=""
            key={form.key('factory_abi')}
            {...form.getInputProps('factory_abi')}
          />

          <Textarea
            label="Factory params"
            placeholder=""
            key={form.key('factory_params')}
            {...form.getInputProps('factory_params')}
          />

          <Textarea
            label="Pair abi"
            placeholder=""
            key={form.key('pair_abi')}
            {...form.getInputProps('pair_abi')}
          />

          <TextInput
            label="Router"
            placeholder="T..."
            key={form.key('router')}
            {...form.getInputProps('router')}
          />

          <Group justify="flex-end" mt="md">
            <Button type="submit" loading={submiting}>Submit</Button>
          </Group>
        </form>
      </Modal>
      {viewing && (
        <Modal
          centered
          opened={viewing !== null}
          onClose={onCloseViewDialog}
          title={"View exchange"}
          size="1000px"
        >
          <Table>
            <Table.Tbody>
              <Table.Tr>
                <Table.Td>Slug</Table.Td>
                <Table.Td>{viewing.slug}</Table.Td>
              </Table.Tr>
              <Table.Tr>
                <Table.Td>Network</Table.Td>
                <Table.Td>{viewing.network}</Table.Td>
              </Table.Tr>
              <Table.Tr>
                <Table.Td>Category</Table.Td>
                <Table.Td>{viewing.category}</Table.Td>
              </Table.Tr>
              <Table.Tr>
                <Table.Td>Factory</Table.Td>
                <Table.Td>
                  <ExplorerLink address={viewing.factory} network={viewing.network} category="address" />
                </Table.Td>
              </Table.Tr>
              <Table.Tr>
                <Table.Td>Router</Table.Td>
                <Table.Td>
                  <ExplorerLink address={viewing.router} network={viewing.network} category="address" />
                </Table.Td>
              </Table.Tr>
            </Table.Tbody>
          </Table>

          <h3>Pairs - {viewing.pairs.length} total</h3>
          <DataTable
            withTableBorder
            withColumnBorders
            striped
            highlightOnHover
            pinLastColumn
            records={viewing.pairs}
            idAccessor="address"
            columns={[
              {
                accessor: 'num',
                title: 'Num',
              },
              {
                accessor: 'address',
                title: 'Address',
                render: (row: any) => (
                  <ExplorerLink address={row.address} network={row.network} category="address" short />
                )
              },
              {
                accessor: 'token0',
                title: 'token0',
                render: (row: any) => (
                  <ExplorerLink address={row.token0} network={row.network} category="address" short />
                )
              },
              {
                accessor: 'token1',
                title: 'token1',
                render: (row: any) => (
                  <ExplorerLink address={row.token1} network={row.network} category="address" short />
                )
              },
              {
                title: 'reserves',
                accessor: 'reserves',
                render: (row: any) => (
                  <>
                    Reserve0: {row.reserve0}<br/>
                    Reserve1: {row.reserve1}<br/>
                    {row.reserve2 && <>Reserve2: {row.reserve2}<br/></>}
                    {row.reserve3 && <>Reserve3: {row.reserve3}<br/></>}
                  </>
                )
              },
              {
                accessor: 'fee',
                title: 'fee',
              },
            ]}>
          </DataTable>

          <Group justify="flex-end" mt="md">
            <Button type="submit" onClick={onCloseViewDialog}>Close</Button>
          </Group>
        </Modal>
      )}
      <Flex mb={10} direction={"row"} justify={"space-between"} align={"center"}>
        <h3>Exchanges</h3>
        <Flex gap={20}>
          <Button onClick={onAddNetwork}>Add exchange</Button>
          <Button loading={fetching} variant="danger" onClick={onReload}>Reload</Button>
        </Flex>
      </Flex>
      <DataTable
        withTableBorder
        withColumnBorders
        striped
        highlightOnHover
        pinLastColumn
        records={filteredRecords}
        minHeight={100}
        idAccessor="slug"
        columns={[
          {
            accessor: 'slug',
            title: 'Slug',
            ellipsis: true,
            width: 200,
          },
          {
            accessor: 'network',
            title: 'Network',
            filtering: (filters.networks || []).length > 0,
            filter: (
              <MultiSelect
                label="Networks"
                data={networks}
                value={filters.networks || []}
                placeholder="Search networks..."
                onChange={onChangeNetworks}
                leftSection={<IconSearch size={16} />}
                clearable
                searchable
              />
            ),
          },
          {
            accessor: 'category',
            title: 'Category',
            filtering: (filters.categories || []).length > 0,
            filter: (
              <MultiSelect
                label="Categories"
                data={categories}
                value={filters.categories || []}
                placeholder="Search categories..."
                onChange={onChangeCategories}
                leftSection={<IconSearch size={16} />}
                clearable
                searchable
              />
            ),
          },
          {
            accessor: 'factory',
            title: 'Factory',
            render: (row: any) => (
              <>
                <ExplorerLink address={row.factory} network={row.network} category="address" short />
                {row.factory_abi && (
                  <IconKey size={16} />
                )}
                {row.pair_abi && (
                  <IconKey size={16} />
                )}
              </>
            ),
          },
          {
            accessor: 'router',
            title: 'Router',
            render: (row: any) => (
              <ExplorerLink address={row.router} network={row.network} category="address" short />
            ),
          },
          {
            accessor: 'last_loaded_pair_num',
            title: 'Pair num',
          },
          {
            accessor: 'actions',
            width: '0%',
            title: <Box mx={6}>Row actions</Box>,
            textAlign: 'right',
            render: (row: any) => (
              <Group gap={4} justify="right" wrap="nowrap">
                <ActionIcon
                  size="sm"
                  variant="subtle"
                  color="green"
                  loading={reloading === row.slug}
                  onClick={(e: React.MouseEvent) => {
                    e.stopPropagation();
                    onViewExchange(row);
                  }}
                >
                  <IconView size={16} />
                </ActionIcon>
                <ActionIcon
                  size="sm"
                  variant="subtle"
                  color="green"
                  loading={reloading === row.slug}
                  onClick={(e: React.MouseEvent) => {
                    e.stopPropagation();
                    onRefreshExchange(row);
                  }}
                >
                  <IconRefresh size={16} />
                </ActionIcon>
                <ActionIcon
                  size="sm"
                  variant="subtle"
                  color="blue"
                  onClick={(e: React.MouseEvent) => {
                    e.stopPropagation();
                    onEditExchange(row);
                  }}
                >
                  <IconEdit size={16} />
                </ActionIcon>
                <ActionIcon
                  size="sm"
                  variant="subtle"
                  color="red"
                  loading={deleting === row.slug}
                  onClick={(e: React.MouseEvent) => {
                    e.stopPropagation();
                    onDeleteNetwork(row.slug);
                  }}
                >
                  <IconTrash size={16} />
                </ActionIcon>
              </Group>
            ),
          },
        ]}
        fetching={fetching}
      />
    </div>
  );
}

export default ExchangesPage;