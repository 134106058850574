import Tokens from '../../mobx/Tokens';
import Wallet from '../../mobx/Wallet';
import BigNumber from "bignumber.js";
import { LiquidationModel } from '../../Components/Models';
import BaseProvider from '../Provider';
import { ethers } from 'ethers';
import { notifications } from '@mantine/notifications';


const MANAGER_PROXY = "0x794a61358d6845594f94dc1db02a252b5b4814ad";

const abi = [
  "function getUserAccountData(address user) view returns (uint256 totalCollateralBase, uint256 totalDebtBase, uint256 availableBorrowsBase, uint256 currentLiquidationThreshold, uint256 ltv, uint256 healthFactor)",
  "function liquidationCall(address collateralAsset, address debtAsset, address user, uint256 debtToCover, bool receiveAToken)",
];

export default class Aavev3Provider extends BaseProvider {
  public contract: any;
  public contractSigner: any;

  constructor() {
    super("arbitrum", "aave_v3");
  }

  private async loadContract() {
    if (this.contract) {
      return;
    }

    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    this.contract = new ethers.Contract(MANAGER_PROXY, abi, provider);
    this.contractSigner = this.contract.connect(signer);
  }

  public async liquidate(setCurrentAction: any, onClose: any, tokens: Tokens, wallet: Wallet, currentLiquidation: LiquidationModel, fromTokenAmount: BigNumber, toTokenAmount: BigNumber) {
    await this.loadContract();

    const health = await this.contract.getUserAccountData(currentLiquidation.borrower);
    console.log(health);
    if (health >= 1) {
      notifications.show({
        title: "Failed to run liquidation",
        message: "Health is greater than 1",
        autoClose: true,
        color: "red"
      });
      return;
    }
    console.log(currentLiquidation.to_amount.token.address, currentLiquidation.from_amount.token.address, currentLiquidation.borrower, fromTokenAmount.toString());
    const tx = await this.contractSigner.liquidationCall(currentLiquidation.to_amount.token.address, currentLiquidation.from_amount.token.address, currentLiquidation.borrower, fromTokenAmount);
    let result = await tx.wait();
    console.log(result);
  }

  public async getToTokens(tokens: Tokens, currentLiquidation: LiquidationModel, tokenAmount: BigNumber): Promise<BigNumber> {
    const fromToken = tokens.getToken(currentLiquidation.from_amount.token);
    const toToken = tokens.getToken(currentLiquidation.to_amount.token);
    return tokenAmount.dividedBy(Math.pow(10, fromToken.digits)).multipliedBy(fromToken.price_usd).dividedBy(toToken.price_usd).multipliedBy(Math.pow(10, toToken.digits));
  }

  public async connectWallet(wallet: Wallet): Promise<[string, string]> {
    const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
    if (accounts.length > 0) {
      wallet.setAddress(accounts[0]);
    }
    return ["", ""];
  }

  public async checkWallet(wallet: Wallet) {
  }
}
