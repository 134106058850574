
import React from 'react';
import { WalletContext } from './Contexts';

import { Badge, Anchor, Flex } from '@mantine/core';

export default function UserMenu() {
  const wallet = React.useContext(WalletContext);
  return (
    <Flex ml={10}>
      {wallet.address &&
        <h5>
          Wallet:
          <Badge>
            <Anchor href="https://tronscan.io/#/wallet" target="_blank">{wallet.address.slice(0, 5)}...{wallet.address.slice(-5)}</Anchor>
          </Badge>
        </h5>
      }
    </Flex>
  );
}