import { useEffect, useState } from 'react';
import { getCurrentUser } from 'aws-amplify/auth';
import { Navigate } from 'react-router-dom';


export const ProtectedRoute = ({ children }: { children: any}) => {
  const [user, setUser] = useState<string | null>(null);

  useEffect(() => {
    async function getUser() {
      try {
        const { userId } = await getCurrentUser();
        setUser(userId);
        console.log(userId);
      } catch (error) {
        console.log('error getting user', error);
        setUser("");
      }
    }
    getUser();
  } , []);

  if (user === null) {
    return null;
  } else if (user === "") {
    return <Navigate to="/login" />;
  }

  return children;
};