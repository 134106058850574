import React from 'react';
import Wallet from '../mobx/Wallet';
import Tokens from '../mobx/Tokens';
import ApiData from '../mobx/ApiData';
import manager from '../mobx/Manager';


export const WalletContext = React.createContext(new Wallet());
export const TokensContext = React.createContext(new Tokens());
export const ApiDataContext = React.createContext(new ApiData());
export const ManagerContext = React.createContext(manager);
