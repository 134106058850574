import BigNumber from "bignumber.js";

const LIQ_CONTRACT = "TKrQrybgYm2abvYzTC4vVAyJMKHxiWJhhp";

class LiqContract {
  public liqAddress: string = "";
  private contract: any;

  private constructor() {
    this.liqAddress = LIQ_CONTRACT;
  }

  private async loadContract() {
    this.contract = await window.tronWeb.contract().at(this.liqAddress);
  }

  public static async getContract() {
    let instance = new LiqContract();
    await instance.loadContract();
    return instance;
  }

  /*
    address tokenSpendAddress,
    address cTokenFromAddress,
    address tokenFromAddress,
    address cTokenToAddress,
    address tokenToAddress,
    uint256 amountFrom,
    address borrower
  */
  public async liquidate(tokenSpendAddress: string, cTokenFromAddress: string, tokenFromAddress: string, cTokenToAddress: string, tokenToAddress: string, amount: BigNumber, borrower: string) {
    let result;
    result = await this.contract.liquidate(tokenSpendAddress, cTokenFromAddress, tokenFromAddress, cTokenToAddress, tokenToAddress, amount.toString(), borrower).send({
        feeLimit: 500_000_000
    });
    return result;
  }
}

export default LiqContract;