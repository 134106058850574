import { useEffect, useState } from 'react';
import { Box, Group, ActionIcon, Button, Flex, Modal, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { DataTable } from 'mantine-datatable';
import Api from '../Services/Api';
import { Edit as IconEdit, Trash as IconTrash } from 'tabler-icons-react';


const NetworksPage = () => {
  const [fetching, setFetching] = useState(false);
  const [records, setRecords] = useState<any[]>([]);
  const [opened, setOpened] = useState(false);
  const [submiting, setSubmiting] = useState(false);
  const [deleting, setDeleting] = useState("");
  const [editing, setEditing] = useState("");

  const form = useForm({
    mode: 'uncontrolled',
    initialValues: {
      name: '',
    },
  });

  const loadRecords = async () => {
    setFetching(true);
    try {
      let response = await Api.getNetworks();
      setRecords(response.data);
    } finally {
      setFetching(false);
    }
  };

  useEffect(() => {
    async function getNetworks() {
      await loadRecords();
    }
    getNetworks();
  }, []);

  const onEditNetwork = (row: any) => {
    setEditing(row.name);
    setOpened(true);
    form.setValues({ name: row.name });
  };

  const onAddNetwork = () => {
    setOpened(true);
    form.setValues({ name: "" });
  };

  const onCloseDialog = () => {
    setOpened(false);
  };

  const onReload = async () => {
    await loadRecords();
  };

  const onSubmit = async (values: any) => {
    setSubmiting(true);
    try {
      if (editing) {
        await Api.updateNetwork(editing, values.name);
      } else {
        await Api.addNetwork(values.name)
      }
      setEditing("");
      setOpened(false);
    } finally {
      setSubmiting(false);
    }

    await loadRecords();
  };

  const onDeleteNetwork = async (name: string) => {
    if (!window.confirm(`Are you sure you want to delete network ${name}?`)) {
      return;
    }

    setDeleting(name);
    try {
      await Api.deleteNetwork(name);
    } finally {
      setDeleting("");
    }

    await loadRecords();
  }

  return (
    <div>
      <Modal
        centered
        opened={opened}
        onClose={onCloseDialog}
        title={"Add network"}
        size="600px"
      >
        <form onSubmit={form.onSubmit(onSubmit)}>
          <TextInput
            withAsterisk
            label="Name"
            placeholder="ethereum"
            key={form.key('name')}
            {...form.getInputProps('name')}
          />

          <Group justify="flex-end" mt="md">
            <Button type="submit" loading={submiting}>Submit</Button>
          </Group>
        </form>
      </Modal>
      <Flex mb={10} direction={"row"} justify={"space-between"} align={"center"}>
        <h3>Networks</h3>
        <Flex gap={20}>
          <Button onClick={onAddNetwork}>Add network</Button>
          <Button loading={fetching} variant="danger" onClick={onReload}>Reload</Button>
        </Flex>
      </Flex>
      <DataTable
        withTableBorder
        withColumnBorders
        striped
        highlightOnHover
        records={records}
        minHeight={100}
        idAccessor="name"
        columns={[
          {
            accessor: 'name',
            title: 'Name',
          },
          {
            accessor: 'actions',
            width: '0%',
            title: <Box mx={6}>Row actions</Box>,
            textAlign: 'right',
            render: (row: any) => (
              <Group gap={4} justify="right" wrap="nowrap">
                <ActionIcon
                  size="sm"
                  variant="subtle"
                  color="blue"
                  onClick={(e: React.MouseEvent) => {
                    e.stopPropagation();
                    onEditNetwork(row);
                  }}
                >
                  <IconEdit size={16} />
                </ActionIcon>
                <ActionIcon
                  size="sm"
                  variant="subtle"
                  color="red"
                  loading={deleting === row.name}
                  onClick={(e: React.MouseEvent) => {
                    e.stopPropagation();
                    onDeleteNetwork(row.name);
                  }}
                >
                  <IconTrash size={16} />
                </ActionIcon>
              </Group>
            ),
          },
        ]}
        fetching={fetching}
      />
    </div>
  );
}

export default NetworksPage;