import BigNumber from "bignumber.js";

const COMPTROLLER_PROXY = "TGjYzgCyPobsNS9n6WcbdLVR9dH7mWqFx7";
const COMPTROLLER_IMPLEMENTATION = "TJZi9eWzCLGBi9tuwvPxnaZTGa2iUpRc8v";


class Comptroller {
  private static instance: Comptroller;
  public contract: any;

  private constructor() {
  }

  private async loadContract() {
    this.contract = await window.tronWeb.contract().at(COMPTROLLER_PROXY);
    let contract = await window.tronWeb.contract().at(COMPTROLLER_IMPLEMENTATION);
    this.contract.loadAbi(contract.abi);
  }

  public static async getInstance() {
    if (!Comptroller.instance) {
      Comptroller.instance = new Comptroller();
      await Comptroller.instance.loadContract();
    }

    return Comptroller.instance;
  }

  public static async getContract() {
    const instance: Comptroller = await Comptroller.getInstance();
    return instance.contract;
  }

  public static async liquidateCalculateSeizeTokens(from_token: string, to_token_string: string, amount: BigNumber) {
    const contract = await Comptroller.getContract();
    let result = await contract.liquidateCalculateSeizeTokens(from_token, to_token_string, amount.toString()).call();
    const error = result[0].toNumber();
    if (error !== 0) {
      throw Error("Error in calling smartcontract " + error.toString());
    }
    const calculatedAmount = new BigNumber(result[1]._hex);
    return calculatedAmount;
  }

  public static async getAccountLiquidity(account: string) {
    const contract = await Comptroller.getContract();
    let result = await contract.getAccountLiquidity(account).call();
    const error = result[0].toNumber();
    if (error !== 0) {
      throw Error("Error in calling smartcontract " + error.toString());
    }
    const shortfall = new BigNumber(result[2]._hex);
    return shortfall
  }

  public static async getAssetsIn(account: string) {
    const contract = await Comptroller.getContract();
    let result = await contract.getAssetsIn(account).call();
    return result.map((r: string) => window.tronWeb.address.fromHex(r));
  }
}

export default Comptroller;
