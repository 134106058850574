import { useContext } from 'react';
import LiquidationTable from '../Components/LiquidationTable';
import Connector from '../Components/Connector';
import { observer } from "mobx-react-lite"
import { WalletContext } from '../Components/Contexts';


const HomePage = observer(() => {
  const wallet = useContext(WalletContext);

  return (
    wallet.address ? <LiquidationTable /> : <Connector />
  )
});

export default HomePage;